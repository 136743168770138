const YojanaData = [
    {
        "id": 1,
        "subject": "पंतप्रधान जन आरोग्य योजना",
        "information": "पंतप्रधान जन आरोग्य योजना (PMJAY पीएमजेएवाय) ग्रामीण आणि शहरी लोकांसाठी कमी उत्पन्न असलेल्या कुटुंबांसाठी एक आरोग्य विमा योजना आहे. या योजनेत स्वस्त आरोग्य सुविधा पुरविल्या जातात.",
        "web_link": "https://www.myscheme.gov.in/hi/schemes/pmjay"
    },
    {
        "id": 2,
        "subject": "पंतप्रधान सुरक्षा विमा योजना",
        "information": "पंतप्रधान सुरक्षा विमा योजनेत केवळ १२ रुपये प्रत्येक वर्षीच्या हप्त्यामध्ये अपघाती मृत्यूचा विमा दिला जातो. अपघाती मृत्यूनंतर २ लाखांचा लाभ वारसांना दिला जातो.",
        "web_link": "https://www.myscheme.gov.in/hi/schemes/pmsby"
    },
    {
        "id": 3,
        "subject": "मुद्रा योजना",
        "information": "उद्योग, व्यवसाय उभारण्यासाठी दहा लाखांपर्यंतचे (१० लाख रुपये) कर्ज या योजनेद्वारे पुरवले जाते.",
        "web_link": "https://www.myscheme.gov.in/hi/schemes/pmmy"
    },
    {
        "id": 4,
        "subject": "पंतप्रधान कौशल्य विकास योजना",
        "information": "या योजनेअंतर्गत विविध सरकारी, निमसरकारी संस्थेमार्फत कुशल मनुष्यबळ प्रशिक्षण पुरविले जाते.",
        "web_link": "https://www.myscheme.gov.in/hi/schemes/pmkvy-sp"
    },
    {
        "id": 5,
        "subject": "वैयक्तिक नळ कनेक्शन अर्थसहाय्य",
        "information": "कुटुंबाचे पुणे महानगरपालिका हद्दीत किमान ३ वर्षे वास्तव्य, कुटुंबाचे सर्व मार्गांनी मिळालेले वार्षिक उत्पन्न रुपये १,००,०००/- च्या आत असलेल्या झोपडीधारक कुटूंबाला नळ कनेक्शनसाठी अर्थसहाय्य.",
        "web_link": "https://dbt.pmc.gov.in/app/index.html#!/social-development-department"
    },
    {
        "id": 6,
        "subject": "वीज कनेक्शनसाठी अर्थसहाय्य",
        "information": "पुणे मनपा हद्दीतील मागासवर्गीय घटकातील नागरिकांना विज कनेक्शनसाठी रू. 5000/- अनुदान दिले जाते.",
        "web_link": "https://dbt.pmc.gov.in/app/index.html#!/social-development-department"
    },
    {
        "id": 7,
        "subject": "एक किंवा दोन मुली असलेल्या कुटुंबाना अनुदान",
        "information": "मुलगा नसताना एक वा दोन मुलींवर कुटुंब नियोजन शस्त्रक्रीया केलेली असल्यास एकच मुलगी असेल तर तिच्या नावे रक्कम रू. 10,000/- व दोन मुली असतील तर प्रत्येकी रक्कम रू. 5000/- युनिट ट्रस्ट ऑफ इंडिया मध्ये गुंतविण्यात येतील. कुटुंब नियोजन शस्त्रकि‘या केल्यानंतर 1 वर्षाचे आत अर्ज सादर करणे आवश्यक आहे.",
        "web_link": "https://dbt.pmc.gov.in/app/index.html#!/social-development-department"
    },
    {
        "id": 8,
        "subject": "विधवा अनुदान",
        "information": "घरातील कर्त्या पुरूषाचे निधन झाल्यास त्याचे विधवा पत्नीस रू. 10,000/- अनुदान (एकदाच) देणेची योजना आहे. मृत व्यक्तिचे वय 18 ते 65 वर्षाच्या दरम्यान असावे. मृत्यू झाल्यानंतर 2 वर्षाच्या आत अनुदानाचा अर्ज सादर करणे बंधनकारक राहिल.",
        "web_link": "https://dbt.pmc.gov.in/app/index.html#!/social-development-department"
    }
]

export default YojanaData;